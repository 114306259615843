// 系统配置
import store from '@/store'
import http from '@/utils/http'
import qs from 'qs'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 查询角色权限
export function getUserRoleByUserid(params) {
  return http({
    url: `${preApi}/menu/getUserRoleByUserid`,
    method: "post",
    data: params
  })
}
// 角色授权
export function authUserMenus(params) {
  return http({
    url: `${preApi}/menu/authUserMenus`,
    method: "post",
    data: params
  })
}
//导出用户账号excel
export function exportUserListExcel(params) {
  return http({
    url: `${preApi}/user/exportUserListExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
// 参数更新
export function modifyParams(params) {
  return http({
    url: `${preApi}/params/modifyParams`,
    method: "post",
    data: params
  })
}
// 参数查询
export function findParamsListByCondition(params) {
  return http({
    url: `${preApi}/params/findParamsListByCondition`,
    method: "post",
    data: params
  })
}
export function findParamsListByConditions(params) {
  return http({
    url: `${preApi}/params/findParamsListByConditions`,
    method: "post",
    data: params
  })
}
export function findFlatParams(params) {
  return http({
    url: `${preApi}/params/findFlatParams`,
    method: "post",
    data: params
  })
}
export function modifyFlatParams(params) {
  return http({
    url: `${preApi}/params/modifyFlatParams`,
    method: "post",
    data: params
  })
}
// 用户列表查询
export function getUserListByCondition(params) {
  return http({
    url: `${preApi}/user/getUserListByCondition`,
    method: "post",
    data: params
  })
}
export function getRepairUserListByCondition(params) {
  return http({
    url: `${preApi}/user/getRepairUserListByCondition`,
    method: "post",
    data: params
  })
}

// 用户(包含维保账号)列表查询
export function getMaintenanceUserListByCondition(params) {
  return http({
    url: `${preApi}/user/getMaintenanceUserListByCondition`,
    method: "post",
    data: params
  })
}
export function getUserList(params) {
  return http({
    url: `${preApi}/user/getUserList`,
    method: "post",
    data: params
  })
}
// 新增用户
export function addUser(params) {
  return http({
    url: `${preApi}/user/addUser`,
    method: "post",
    data: params
  })
}
// 修改用户
export function editUser(params) {
  return http({
    url: `${preApi}/user/modifyUser`,
    method: "post",
    data: params
  })
}
// 用户删除
export function deleteUser(params) {
  return http({
    url: `${preApi}/user/deleteUser`,
    method: "post",
    data: params
  })
}
// 用户角色查询
export function getRoleListByUserid(params) {
  return http({
    url: `${preApi}/role/getRoleListByUserid`,
    method: "post",
    data: params
  })
}
// 用户详情查询
export function getUserByUserid(params) {
  return http({
    url: `${preApi}/user/getUserByUserid`,
    method: "post",
    data: params
  })
}
// 用户分配角色
export function authRole(params) {
  return http({
    url: `${preApi}/role/authRole`,
    method: "post",
    data: params
  })
}
// 个人信息修改
export function modifyPersonInfo(params) {
  return http({
    url: `${preApi}/user/modifyPersonInfo`,
    method: "post",
    data: params
  })
}
// 重置密码
export function resetPassword(params) {
  return http({
    url: `${preApi}/user/resetPassword`,
    method: "post",
    data: params
  })
}
// 远控二次授权
export function resetPermission(params) {
  return http({
    url: `${preApi}/user/resetPermission`,
    method: "post",
    data: params
  })
}

// 角色列表查询
export function getRoleListByCondition(params) {
  return http({
    url: `${preApi}/role/getRoleListByCondition`,
    method: "post",
    data: params
  })
}
// 新增角色
export function addRole(params) {
  return http({
    url: `${preApi}/role/addRole`,
    method: "post",
    data: params
  })
}
// 修改角色
export function editRole(params) {
  return http({
    url: `${preApi}/role/modifyRole`,
    method: "post",
    data: params
  })
}
// 角色删除
export function deleteRole(params) {
  return http({
    url: `${preApi}/role/deleteRole`,
    method: "post",
    data: params
  })
}
// 查询菜单集合
export function getMenuListByRoleid(params) {
  return http({
    url: `${preApi}/menu/getMenuListByRoleid`,
    method: "post",
    data: params
  })
}
export function getMenuListByUserid(params) {
  return http({
    url: `${preApi}/menu/getMenuListByUserid`,
    method: "post",
    data: params
  })
}
// 角色授权
export function authMenu(params) {
  return http({
    url: `${preApi}/menu/authMenu`,
    method: "post",
    data: params
  })
}

// 菜单查询
export function getAllMenuTree() {
  return http({
    url: `${preApi}/menu/getAllMenuTree`,
    method: "post"
  })
}
// 新增菜单
export function addMenu(params) {
  return http({
    url: `${preApi}/menu/addMenu`,
    method: "post",
    data: params
  })
}
// 修改菜单
export function editMenu(params) {
  return http({
    url: `${preApi}/menu/modifyMenu`,
    method: "post",
    data: params
  })
}
// 菜单删除
export function deleteMenu(params) {
  return http({
    url: `${preApi}/menu/deleteMenu`,
    method: "post",
    data: params
  })
}

// 播放参数下发
export function setPlayerParams(params) {
  return http({
    url: `${preApi}/event/setPlayerParams`,
    method: "post",
    data: params
  })
}

// 查询日志列表
export function getLogListByCondition(params) {
  return http({
    url: `${preApi}/log/getLogListByCondition`,
    method: "post",
    data: params
  })
}
// 删除日志
export function deleteLog(params) {
  return http({
    url: `${preApi}/log/deleteLog`,
    method: "post",
    data: params
  })
}

//导出系统日志excel
export function exportLogExcel(params) {
  return http({
    url: `${preApi}/log/exportLogExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 维护组列表查询
export function getMentenacegroupListByCondition(params) {
  return http({
    url: `${preApi}/mentenacegroup/getMentenacegroupListByCondition`,
    method: "post",
    data: params
  })
}
// 新增维护组
export function addMentenacegroup(params) {
  return http({
    url: `${preApi}/mentenacegroup/addMentenacegroup`,
    method: "post",
    data: params
  })
}
// 修改维护组
export function modifyMentenacegroup(params) {
  return http({
    url: `${preApi}/mentenacegroup/modifyMentenacegroup`,
    method: "post",
    data: params
  })
}
// 维护组删除
export function deleteMentenacegroup(params) {
  return http({
    url: `${preApi}/mentenacegroup/deleteMentenacegroup`,
    method: "post",
    data: params
  })
}
// 查询维护组详情
export function getMentenacegroupById(params) {
  return http({
    url: `${preApi}/mentenacegroup/getMentenacegroupById`,
    method: "post",
    data: params
  })
}
// 绑定维护组成员
export function bindMentenacemember(params) {
  return http({
    url: `${preApi}/mentenacegroup/bindMentenacemember`,
    method: "post",
    data: params
  })
}
//根据维保组成员id查询维保组
export function getMaintenanceGroupByUserId(params) {
  return http({
    url: `${preApi}/mentenacegroup/getMaintenanceGroupByUserId`,
    method: "post",
    data: params
  })
}
// 查询字典表
export function getDictByDicType(params) {
  let key = qs.stringify(params);
  if(store.state.cache.dictMap[key]) {
    return new Promise((resolve, reject) => {
      resolve(store.state.cache.dictMap[key])
    })
  }else {
    return http({
      url: `${preApi}/dict/getDictByDicType`,
      method: "post",
      data: params
    }).then(res => {
      store.commit('cache/addDict', {
        key: key,
        data: res
      })
      return res;
    })
  }
}
export function getDictionaryList(params) {
  return http({
    url: `${preApi}/dictionary/getDictionaryList`,
    method: "post",
    data: params
  })
}
export function getEventTypeCodeList(params) {
  return http({
    url: `${preApi}/dictionary/getEventTypeCodeList`,
    method: "post",
    data: params
  })
}
// 根据父区域编码查询子区域列表
export function geteChildListByParentcode(params) {
  let key = qs.stringify(params);
  if(store.state.cache.areaMap[key]) {
    return new Promise((resolve, reject) => {
      resolve(store.state.cache.areaMap[key])
    })
  }else {
    return http({
      url: `${preApi}/area/geteChildListByParentcode`,
      method: "post",
      data: params
    }).then(res => {
      store.commit('cache/addArea', {
        key: key,
        data: res
      })
      return res;
    })
  }
}
// 根据当前区域编码查询父区域树型列表
export function getParentTreeBycode(params) {
  return http({
    url: `${preApi}/area/getParentTreeBycode`,
    method: "post",
    data: params
  })
}

export function getSystemInfo() {
  return http({
    url: `${preApi}/system/getSystemInfo`,
    method: "post",
    // data: "params"
  })
}

// 根据父设备编码查询子设备列表
export function getChildDeviceListByParentCode(params) {
  let key = qs.stringify(params);
  if(store.state.cache.deviceMap[key]) {
    return new Promise((resolve, reject) => {
      resolve(store.state.cache.deviceMap[key])
    })
  }else {
    return http({
      url: `${preApi}/device/getChildDeviceListByParentCode`,
      method: "post",
      data: params
    }).then(res => {
      store.commit('cache/addDevice', {
        key: key,
        data: res
      })
      return res;
    })
  }
}
// 根据当前区域编码查询父区域树型列表
export function getParentDeviceTreeByCode(params) {
  return http({
    url: `${preApi}/device/getParentDeviceTreeByCode`,
    method: "post",
    data: params
  })
}

// 删除报告
export function deleteReport(params) {
  return http({
    url: `${preApi}/monitorpoint/deleteReport`,
    method: "post",
    data: params
  })
}
// 用户角色查询
export function getReportListByCondition(params) {
  return http({
    url: `${preApi}/monitorpoint/getReportListByCondition`,
    method: "post",
    data: params
  })
}


//获取字典信息
export function getDictionaryListByCondition(params) {
  return http({
    url: `${preApi}/dict/getDictionaryListByCondition`,
    method: "post",
    data: params
  })
}
//增加字典信息
export function addDictionary(params) {
  return http({
    url: `${preApi}/dict/addDict`,
    method: "post",
    data: params
  })
}
//删除一个字典信息
export function deleteOneDictionary(params) {
  return http({
    url: `${preApi}/dict/deleteDict`,
    method: "post",
    data: params
  })
}

//修改一个字典信息
export function editDictionary(params) {
  return http({
    url: `${preApi}/dict/modifyDict`,
    method: "post",
    data: params
  })
}

//获取字典类型信息
export function getDictionaryTypeListByCondition(params) {
  return http({
    url: `${preApi}/dict/getDictionaryTypeListByCondition`,
    method: "post",
    data: params
  })
}
//增加字典类型信息
export function addDictionaryType(params) {
  return http({
    url: `${preApi}/dict/addDictionaryType`,
    method: "post",
    data: params
  })
}
//删除一个字典类型信息
export function deleteOneDictionaryType(params) {
  return http({
    url: `${preApi}/dict/deleteOneDictionaryType`,
    method: "post",
    data: params
  })
}

//修改一个字典类型信息
export function editDictionaryType(params) {
  return http({
    url: `${preApi}/dict/editDictionaryType`,
    method: "post",
    data: params
  })
}