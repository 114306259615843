<!--通知-->
<template>
  <page-container title="通知" :breadcrumb="breadcrumb">
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-tabs size="small" @change="callback">
          <a-tab-pane key="tab1" tab="通知列表">
            <!--    查询栏-->
            <div class="box-container" style="margin-top: 10px;">
              <div class="box-container-inner">
                <div class="simple-query">
                  <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
                    <a-form-model-item label="主题" prop="topic" style="width: 25%">
                      <a-input v-model="queryParams.topic"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="内容" prop="content" style="width: 25%">
                      <a-input v-model="queryParams.content"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="是否作废" prop="is_history" style="width: 25%">
                      <a-select v-model="queryParams.is_history">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <div class="query-btns" style="float: right;width: 24%">
                      <a-button @click="resetQueryParams">重置</a-button>
                      <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--                      <a-button type="link" @click="showAdvanced=!showAdvanced">展开-->
<!--                        <a-icon :type="showAdvanced?'up':'down'"></a-icon>-->
<!--                      </a-button>-->
                    </div>
                  </a-form-model>
                </div>
                <div v-show="showAdvanced" class="advanced-query">
                  <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
<!--                    <a-form-model-item label="是否失效" prop="is_invalid" style="width: 25%">-->
<!--                      <a-select v-model="queryParams.is_invalid">-->
<!--                        <a-select-option value="">全部</a-select-option>-->
<!--                        <a-select-option :value="0">否</a-select-option>-->
<!--                        <a-select-option :value="1">是</a-select-option>-->
<!--                      </a-select>-->
<!--                    </a-form-model-item>-->
                  </a-form-model>
                </div>
              </div>
            </div>
            <div class="table-header">
              <h3 class="table-title">通知列表</h3>
              <div class="table-btns">
                <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
                <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
              </div>
            </div>
            <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
              <span slot="notice_type" slot-scope="value">
                <span class="text">{{ noticeTypes[value] }}</span>
              </span>
              <span slot="is_history" slot-scope="value">
                <span class="text">{{ value==='1'?'是':'否'}}</span>
              </span>
              <span slot="start_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="end_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="create_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="operation" slot-scope="value, record">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <a-icon type="menu"/>
                  </a>
                  <a-menu slot="overlay" @click="operationClick">
                    <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
<!--                    <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>-->
                    <a-menu-item :key="'delete-'+record.id">作废</a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </a-table>
            <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
          <a-tab-pane key="tab2" tab="通知组">
            <!--    查询栏-->
            <div class="box-container" style="margin-top: 10px;">
              <div class="box-container-inner">
                <div class="simple-query">
                  <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
                    <a-form-model-item label="通知组名称" prop="group_name" style="width: 25%">
                      <a-input v-model="queryParams.group_name"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="通知组成员" prop="group_user_name" style="width: 25%">
                      <a-input v-model="queryParams.group_user_name"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="是否作废" prop="is_history" style="width: 25%">
                      <a-select v-model="queryParams.is_history">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">否</a-select-option>
                        <a-select-option :value="1">是</a-select-option>
                      </a-select>
                    </a-form-model-item>
                    <div class="query-btns" style="float: right;width: 20%">
                      <a-button @click="resetQueryParams">重置</a-button>
                      <a-button type="primary" class="after-btn" @click="getTableData1(true)">查询</a-button>
<!--                      <a-button type="link" @click="showAdvanced=!showAdvanced">展开-->
<!--                        <a-icon :type="showAdvanced?'up':'down'"></a-icon>-->
<!--                      </a-button>-->
                    </div>
                  </a-form-model>
                </div>
                <div v-show="showAdvanced" class="advanced-query">
                  <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
<!--                    <a-form-model-item label="是否失效" prop="is_invalid" style="width: 25%">-->
<!--                      <a-select v-model="queryParams.is_invalid">-->
<!--                        <a-select-option value="">全部</a-select-option>-->
<!--                        <a-select-option :value="0">否</a-select-option>-->
<!--                        <a-select-option :value="1">是</a-select-option>-->
<!--                      </a-select>-->
<!--                    </a-form-model-item>-->
                  </a-form-model>
                </div>
              </div>
            </div>
            <div class="table-header">
              <h3 class="table-title">通知组列表</h3>
              <div class="table-btns">
                <a-button type="primary" @click="showModal1('add')"><a-icon type="plus"></a-icon>新建</a-button>
                <a-icon type="redo" class="after-btn table-refresh" @click="getTableData1(true)"></a-icon>
              </div>
            </div>
            <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow1">
              <span slot="notice_type" slot-scope="value">
                <span class="text">{{ noticeTypes[value] }}</span>
              </span>
              <span slot="is_history" slot-scope="value">
                <span class="text">{{ value==='1'?'是':'否'}}</span>
              </span>
              <span slot="start_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="end_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="create_time" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="operation" slot-scope="value, record">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <a-icon type="menu"/>
                  </a>
                  <a-menu slot="overlay" @click="operationClick1">
                    <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                    <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                    <a-menu-item :key="'delete-'+record.id">作废</a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </a-table>
            <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <!--    新建、修改通知页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="715" :dialog-style="{top: '25px'}" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType==='add'||modalType==='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="类型" prop="notice_type">
          <a-select :disabled="true" style="width: 330px" v-model="modalForm.notice_type">
            <a-select-option :value="1">通知</a-select-option>
            <a-select-option :value="2">公告</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="通知组" prop="group_id">
          <a-select :disabled="modalType==='detail'" v-model="modalForm.group_id">
            <a-select-option v-for="(item, index) in notificationGroupList" :key="index" :value="item.group_id">{{item.group_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label="生效时间" prop="start_time">-->
<!--          <a-date-picker :disabled="modalType==='detail'" style="width: 330px" v-model.trim="modalForm.start_time" show-time placeholder="请选择日期和时间"></a-date-picker>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="失效时间" prop="end_time">-->
<!--          <a-date-picker :disabled="modalType==='detail'" style="width: 330px" v-model.trim="modalForm.end_time" show-time placeholder="请选择日期和时间"></a-date-picker>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="主题" prop="topic">
          <a-input :disabled="modalType==='detail'" style="width: 330px" v-model="modalForm.topic"></a-input>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <a-textarea :disabled="modalType==='detail'" style="width: 330px" v-model="modalForm.content"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--    新建、修改通知组-->
    <a-modal v-model="modalVisible1" :title="modalTitle" :width="715" :dialog-style="{top: '25px'}" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType==='add'||modalType==='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm1" :model="modalForm1" :rules="modalRules1" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="通知组名称" prop="group_name">
          <a-input :disabled="modalType==='detail'" style="width: 330px" v-model="modalForm1.group_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="通知组成员" prop="group_user_id">
          <a-input :value="modalForm1.group_user_name" disabled style="width: 245px;" />
          <a-button v-if="modalType==='edit'||modalType==='add'" type="primary" @click="openSelectUserModal()" style="margin-left: 10px;">请选择</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <SelectNotificationUser key="selectmembers" :visible.sync="selectGroupMembersVisible" @get-selected-rows="getGroupMembers" :default-selected="groupMembers" :params="selectUserParams"></SelectNotificationUser>
  </page-container>
</template>
<script>
import {
  addPerformanceNotification,
  getNotificationListByCondition,
  deleteOneNotification,
  editNotification,

  addPerformanceNotificationGroupUser,
  getNotificationGroupUserListByCondition,
  deleteOneNotificationGroupUser,
  editNotificationGroupUser, getNotificationGroupList, setNotificationGroupUserHistory, setNotificationHistory
} from 'A/performance'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";
import {SHOW_ALL} from "ant-design-vue/lib/vc-tree-select";
import {getTreeOfUserDepHasUser} from "A/jcgn";
import SelectNotificationUser from "C/modals/SelectNotificationUser.vue";
import {mapState} from "vuex";
export default {
  name: 'notification',
  components: {SelectNotificationUser},
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      noticeTypes:["","通知","公告"],
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '通知公告',
          path: ''
        },
        {
          name: '通知',
          path: ''
        }
      ],
      queryParams: {
        notice_type:1,
        is_history:0,
        // is_invalid:0,
        userdepid:'',
        // start_time:"",
        // end_time:"",
        group_name:"",
        group_user_name:"",
      },
      tableColumns: [
        {
          title: '类型',
          dataIndex: 'notice_type',
          key: 'notice_type',
          scopedSlots: {customRender: 'notice_type'}
        },
        {
          title: '主题',
          dataIndex: 'topic',
          key: 'topic',
          scopedSlots: {customRender: 'topic'}
        },
        {
          title: '内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
          scopedSlots: {customRender: 'content'}
        },
        {
          title: '通知组',
          dataIndex: 'group_name',
          key: 'group_name',
          ellipsis: true,
          scopedSlots: {customRender: 'group_name'}
        },
        // {
        //   title: '生效时间',
        //   dataIndex: 'start_time',
        //   key: 'start_time',
        //   scopedSlots: {customRender: 'start_time'}
        // },
        // {
        //   title: '失效时间',
        //   dataIndex: 'end_time',
        //   key: 'end_time',
        //   scopedSlots: {customRender: 'end_time'}
        // },
        {
          title: '上传时间',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: {customRender: 'create_time'}
        },
        {
          title: '是否作废',
          dataIndex: 'is_history',
          key: 'is_history',
          ellipsis: true,
          scopedSlots: {customRender: 'is_history'}
        },
        {
          title: '操作',
          key: 'operation',
          width:70,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      selectDeptList: [],
      isShow:true,
      notificationGroupList: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      activeKey:"tab1",
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        is_history:0,
        notice_type:'',
        topic:'',
        content:'',
        userdep_ids: '',
        userdep_names: '',
        group_id: null,
        // start_time:"",
        // end_time:"",
      },
      modalRules: {
        notice_type: [{required: true, message: '请选择通知公告类型'}],
        userdep_ids: [{required: true, message: '请选择公司/项目'}],
        topic: [{required: true, message: '请输入主题'}],
        content: [{required: true, message: '请输入内容'}],
        group_id: [{required: true, message: '请选择通知组'}],
        // start_time: [{required: true, message: '请选择生效时间'}],
        // end_time: [{required: true, message: '请选择失效时间'}]
      },
      modalVisible1: false,
      modalType1: '',
      modalForm1: {
        id:0,
        is_history:0,
        group_name:'',
        group_user_id:'',
        group_user_name:'',
      },
      modalRules1: {
        group_name: [{required: true, message: '请输入通知组名称'}],
        group_user_id: [{required: true, message: '请选择通知组成员'}],
      },
      selectedNotification:null,
      userDepList:[],
      selectedUserDepIds:null,

      selectGroupMembersVisible: false,
      groupMembers: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
    selectUserParams() {
      return {
        usertypes: "1",
        rolename: ""
      }
    }
  },
  watch: {
    selectDeptList(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    'selectedUserDepIds'(val) {
      if(val && val.length) {
        this.modalForm.userdep_ids=""
        this.modalForm.userdep_names=""
        for(let i=0;i<val.length;i++){
          this.modalForm.userdep_ids+=val[i].value+","
          this.modalForm.userdep_names+=val[i].label+","
        }
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    SHOW_ALL() {
      return SHOW_ALL
    },
    init() {
      this.getNotificationGroup()
      this.getTableData();
      this.getAllUserDepList()
    },
    getNotificationGroup(){
      getNotificationGroupList({}).then(res=>{
        if(res&&res.returncode==='0') {
          this.notificationGroupList = res.item
        }
      })
    },
    resetQueryParams() {
      this.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      this.queryParams.creator=this.userInfo.userid
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getNotificationListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      if(this.activeKey==="tab2"){
        this.getTableData1();
      }else{
        this.getTableData();
      }
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    callback(key){
      if(key==="tab1"){
        this.queryParams.notice_type=1
        this.queryParams.content=""
        this.currentPage=1
        this.tableColumns=[
          {
            title: '类型',
            dataIndex: 'notice_type',
            key: 'notice_type',
            scopedSlots: {customRender: 'notice_type'}
          },
          {
            title: '主题',
            dataIndex: 'topic',
            key: 'topic',
            scopedSlots: {customRender: 'topic'}
          },
          {
            title: '内容',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            scopedSlots: {customRender: 'content'}
          },
          {
            title: '通知组',
            dataIndex: 'group_name',
            key: 'group_name',
            ellipsis: true,
            scopedSlots: {customRender: 'group_name'}
          },
          // {
          //   title: '生效时间',
          //   dataIndex: 'start_time',
          //   key: 'start_time',
          //   scopedSlots: {customRender: 'start_time'}
          // },
          // {
          //   title: '失效时间',
          //   dataIndex: 'end_time',
          //   key: 'end_time',
          //   scopedSlots: {customRender: 'end_time'}
          // },
          {
            title: '上传时间',
            dataIndex: 'create_time',
            key: 'create_time',
            scopedSlots: {customRender: 'create_time'}
          },
          {
            title: '是否作废',
            dataIndex: 'is_history',
            key: 'is_history',
            ellipsis: true,
            scopedSlots: {customRender: 'is_history'}
          },
          {
            title: '操作',
            key: 'operation',
            width:70,
            scopedSlots: {customRender: 'operation'}
          },
        ]
        this.activeKey="tab1"
        this.getTableData()
      }else if(key==="tab2"){
        this.queryParams.notice_type=1
        this.currentPage=1
        this.tableColumns=[
          {
            title: '通知组名称',
            dataIndex: 'group_name',
            key: 'group_name',
            scopedSlots: {customRender: 'group_name'}
          },
          {
            title: '通知组成员',
            dataIndex: 'group_user_name',
            key: 'group_user_name',
            scopedSlots: {customRender: 'group_user_name'}
          },
          {
            title: '创建人',
            dataIndex: 'creator_name',
            key: 'creator_name',
            ellipsis: true,
            scopedSlots: {customRender: 'creator_name'}
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
            scopedSlots: {customRender: 'create_time'}
          },
          {
            title: '是否作废',
            dataIndex: 'is_history',
            key: 'is_history',
            ellipsis: true,
            scopedSlots: {customRender: 'is_history'}
          },
          {
            title: '操作',
            key: 'operation',
            width:70,
            scopedSlots: {customRender: 'operation'}
          },
        ]
        this.activeKey="tab2"
        this.getTableData1()
      }
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type === 'add') {
        this.modalVisible = true;
        this.modalForm.notice_type=this.queryParams.notice_type
        this.modalForm.is_history=0
        this.modalForm.topic=''
        this.modalForm.content=''
        this.modalForm.userdep_ids= ''
        this.modalForm.userdep_names= ''
        this.modalForm.group_id= null
        // this.modalForm.start_time= null
        // this.modalForm.end_time= null
        this.selectedUserDepIds=null
      } else {
        let selectedUserDepIds = []
        let userdep_ids=record.userdep_ids?record.userdep_ids.split(","):""
        let userdep_names=record.userdep_names?record.userdep_names.split(","):""
        for(let i=0;i<userdep_ids.length-1;i++) {
          selectedUserDepIds.push({label: userdep_names[i], value: userdep_ids[i]})
        }
        this.selectedUserDepIds=selectedUserDepIds
        this.modalForm.id=record.id
        this.modalForm.notice_type=record.notice_type
        this.modalForm.topic=record.topic
        this.modalForm.content=record.content
        this.modalForm.userdep_ids=""
        this.modalForm.userdep_names=""
        this.modalForm.group_id=record.group_id
        // this.modalForm.start_time=moment(record.start_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        // this.modalForm.end_time=moment(record.end_time,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        this.modalVisible = true;
      }
    },
    getAllUserDepList(){
      getTreeOfUserDepHasUser({}).then(res=>{
        if(res&&res.returncode==='0'){
          this.userDepList=res.item
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(notification_id) {
      if (notification_id) {
        let params = {
          id:notification_id
        }
        setNotificationHistory(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType === 'add' || this.modalType === 'edit') {
        if(this.activeKey==="tab2"){
          this.$refs.modalForm1.validate(valid => {
            if (valid) {
              this.addOrEdit1();
            } else {
              return false;
            }
          })
        }else{
          this.$refs.modalForm.validate(valid => {
            if (valid) {
              this.addOrEdit();
            } else {
              return false;
            }
          })
        }
      } else {
        this.modalVisible = false;
        this.modalVisible1 = false;
      }
    },
    //添加或者编辑物业收支单
    addOrEdit() {
      this.showLoading();
      // this.modalForm.start_time=moment(this.modalForm.start_time).format("YYYYMMDDHHmmss")
      // this.modalForm.end_time=moment(this.modalForm.end_time).format("YYYYMMDDHHmmss")
      let params = {
        notice_type: this.modalForm.notice_type,
        is_history: this.modalForm.is_history,
        topic:this.modalForm.topic,
        content:this.modalForm.content,
        userdep_ids:this.modalForm.userdep_ids,
        userdep_names:this.modalForm.userdep_names,
        group_id:this.modalForm.group_id,
        // start_time: this.modalForm.start_time,
        // end_time: this.modalForm.end_time,
      };
      if (this.modalType === 'add') {
        addPerformanceNotification(params).then(this.addOrEditResult);
      } else if (this.modalType === 'edit') {
        params.id = this.modalForm.id;
        editNotification(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode === '0') {
        this.$message.success('操作成功');
        if(this.activeKey==="tab2"){
          this.modalVisible1 = false;
          this.notificationGroupList=[]
          this.getNotificationGroup()
          this.getTableData1();
        }else{
          this.modalVisible = false;
          this.getTableData();
        }
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
      this.modalVisible1 = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
      this.$refs.modalForm1.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      this.selectedNotification = getItemFromArrayByKey(this.tableData, 'id', id);
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedNotification)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedNotification)
      }else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedNotification)
      }
    },
    handleClickRow(record,index){
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    },
    getTableData1(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      this.queryParams.creator=this.userInfo.userid
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getNotificationGroupUserListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          this.tableData = res.item
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showModal1(type, value, record) {
      this.modalType = type;
      if (type === 'add') {
        this.modalVisible1 = true;
        this.groupMembers=null
        this.modalForm1.group_name=''
        this.modalForm1.is_history=0
        this.modalForm1.group_user_id=''
        this.modalForm1.group_user_name=''
      } else {
        this.modalForm1.id=record.id
        this.modalForm1.group_name=record.group_name
        this.modalForm1.is_history=record.is_history
        this.modalForm1.group_user_id=record.group_user_id
        this.modalForm1.group_user_name=record.group_user_name
        this.modalVisible1 = true;
      }
    },
    openSelectUserModal(){
      if(this.modalType==='edit'){
        let menmbersid = this.modalForm1.group_user_id.split(",")
        let menmbersname = this.modalForm1.group_user_name.split(",")
        let groupMembers = []
        for(let i=0;i<menmbersid.length;i++){
          groupMembers.push({"userid":Number(menmbersid[i]),"username":menmbersname[i]})
        }
        this.groupMembers=groupMembers
      }
      this.selectGroupMembersVisible=true
    },
    getGroupMembers(selectedRows) {
      this.groupMembers = selectedRows;
      if(this.groupMembers && this.groupMembers.length) {
        this.modalForm1.group_user_id = this.groupMembers.map(item => item.userid).join(',')
        this.modalForm1.group_user_name = this.groupMembers.map(item => item.username).join(',')
      }
    },
    addOrEdit1() {
      this.showLoading();
      let params = {
        group_name: this.modalForm1.group_name,
        is_history: this.modalForm1.is_history,
        group_user_id:this.modalForm1.group_user_id,
        group_user_name:this.modalForm1.group_user_name,
      };
      if (this.modalType === 'add') {
        addPerformanceNotificationGroupUser(params).then(this.addOrEditResult);
      } else if (this.modalType === 'edit') {
        params.id = this.modalForm1.id;
        editNotificationGroupUser(params).then(this.addOrEditResult)
      }
    },
    operationClick1({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      this.selectedNotification = getItemFromArrayByKey(this.tableData, 'id', id);
      if ('edit' == type) {
        this.showModal1('edit', id, this.selectedNotification)
      } else if ('detail' == type) {
        this.showModal1('detail', id, this.selectedNotification)
      }else if ('delete' == type) {
        this.deleteConfirm1(id, this.selectedNotification)
      }
    },
    deleteConfirm1(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete1(record.id);
      }).catch(()=>{
      });
    },
    delete1(notification_id) {
      if (notification_id) {
        let params = {
          id:notification_id
        }
        setNotificationGroupUserHistory(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.notificationGroupList=[]
            this.getNotificationGroup()
            this.getTableData1();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    handleClickRow1(record,index){
      return {
        on: {
          click: () => {
            this.operationClick1({key:"detail-"+String(record.id)});
          }
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.file_path-view {
  display: flex;
  align-items: center;
  .text {
    display: inline-block;
    margin-left: 7px;
    //border: 1px solid black;
    color: #57c921;
    &.red {
      color: #e70c0c;
    }
  }
}
</style>